<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-row>
                    <a-col :span="24" style="text-align: left">
                        <a-date-picker mode="year" @openChange="openChange" :defaultValue="momentYear?momentYear:null"
                                       :value="momentYear?momentYear:null" :open="isOpen" format="YYYY"
                                       @panelChange="handleChange"/>
                    </a-col>
					<a-col :span="24" style="margin-top: 20px">
						<a-spin :spinning="isLoading" style="position: relative;">
							<div
							    style="width:100%;height: 1px;border-top:1px solid #e8e8e8;position: absolute;z-index: 2;background-color: white;">
							
							</div>
							<div style="width:100%;overflow-y: auto;height: 100%;table-layout: fixed;z-index: 1;" :style="styObj">
							    <table style="text-align: center;width: 100%;" id="tables">
							        <thead>
							            <th style="border:1px solid #e8e8e8;height: 50px;text-align: center;vertical-align:middle;width: 120px;height: 50px;position: sticky;top: 0;;background: #ffffff;table-layout: fixed;"
							                class="body2">{{l('month')}}</th>
							            <th v-for="item in DepartmentName" :title="item"
							                style="border:1px solid #e8e8e8;vertical-align:middle;position: sticky;top: 0;background: #ffffff;"
							                class="body">{{item}}</th>
							        </thead>
							
							        <tr v-for="(item, number) in DepartmentList">
							            <td style="border:1px solid #e8e8e8;width: 120px;height: 50px;" class="body2">
							                {{item.month}}</td>
							            <td v-for="(items, index) in item.count" align="center" style="border:1px solid #e8e8e8;width: 200px;height: 50px" class="body3">
											{{items}}
							            </td>
							        </tr>
							    </table>
							</div>
						</a-spin>
					</a-col>
                </a-row>
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
    import {
        AppComponentBase
    } from "@/shared/component-base";
	import ModalComponentBase from '../../../shared/component-base/modal-component-base';
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {
        ReportServiceProxy,
		KanBanServiceProxy
    } from '../../../shared/service-proxies';
    import moment from "moment";

    let _this;

    export default {
        name: "Statistics-on-sectoral-issues",
        mixins: [ModalComponentBase],
        components: {
            
        },
        created() {
            _this = this;
            this.ReportServiceProxy = new ReportServiceProxy(this.$apiUrl, this.$api);
			this.KanBanServiceProxy = new KanBanServiceProxy(this.$apiUrl, this.$api);
        },
        async mounted() {
			this.year = (new Date()).getFullYear()
			this.momentYear = moment(this.year + '-01-01')
			this.fullData(); // 模态框必须,填充数据到data字段
            window.addEventListener('resize', this.changeHeight);
            this.changeHeight();
			this.getData();
        },
        data() {
            return {
                zh_CN,
                isEdit: false,
                isLook: false,
                isLoading: false,
				isOpen: false,
				momentYear: undefined,
				year: undefined,
				DepartmentName: [],
				styObj: {
				    height: 0
				},
				DepartmentList: []
            }
        },
        methods: {
            moment,
			
			//div高度
			changeHeight() {
			    this.styObj.height = window.innerHeight - 199 + 'px';
			},
			
			//是否打开选择
			openChange(e) {
			    if (e) {
			        this.isOpen = true;
			    } else {
			        this.isOpen = false;
			    }
			},
			
			//下拉选中年份
			handleChange(value) {
			    this.year = value.format('YYYY');
				this.momentYear = value;
			    this.isOpen = false;
			    this.getData();
			},
			
			getData() {
				this.isLoading = true;
				this.ReportServiceProxy.getEveryMonthIssueCountForDept(
					this.year
				).finally(() => {
				    this.isLoading = false;
				}).then(res => {
					console.log(res)
					this.DepartmentName = [];
					this.DepartmentList = [];
					for(let i = 1; i<= 12; i++) {
						let data = {
							month: i,
							count: []
						};
						this.DepartmentList.push(data);
					}
					
					let Total = {
						month: this.l('Total'),
						count: []
					};
					this.DepartmentList.push(Total);
					
					for(let i in res) {
						this.DepartmentName.push(res[i].name);
						for(let l in res[i].monthCount) {
							this.DepartmentList[l].count.push(res[i].monthCount[l].count)
						}
					}
				});
			}
        }
    }
</script>

<style scoped>
    .body {
        min-width: 80px;
        min-height: 50px;
    }

    .body1 {
        min-width: 100px;
        min-height: 50px;
    }
	
	.body2 {
	    min-width: 100px;
	    min-height: 50px;
	}
	
	.body3 {
	    min-width: 100px;
	    min-height: 50px;
	}
	
	.body4 {
	    min-width: 100px;
	    min-height: 200px;
	}
	
	.juzhong {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	th:nth-child(1){
        position: sticky;
        left: 0;
		top: 0;
		z-index: 2;
		background: #ffffff;
    }
	
    td:nth-child(1){
        position: sticky;
        left: 0;
		z-index: 1;
		background: #ffffff;
    }
	
	th:nth-child(2){
	    position: sticky;
	    left: 100px;
		top: 0;
		z-index: 2;
		background: #ffffff;
	}
	
	td:nth-child(2){
	    position: sticky;
	    left: 100px;
		z-index: 1;
		background: #ffffff;
	}
</style>